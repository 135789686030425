<template>
  <el-popover
    v-model="visible"
    :placement="placement"
    :width="+width"
    popper-class="filter-popover"
    trigger="click"
  >
    <slot></slot>
    <div class="btnBox flex_c_c">
      <div class="cancel flex_c_c" @click="reset">重置</div>
      <div class="ok flex_c_c" @click="ok">筛选</div>
    </div>
    <div slot="reference" class="reference flex_c_c" :class="{ active: isActive }">
      <svg-icon iconClass="filter" className="icon" />
      <span>{{ isActive? '已筛选' :'筛选' }}</span>
    </div>
  </el-popover>
</template>
<script>
export default {
  name: 'BaseFilter',
  props: {
    placement: {
      type: String,
      default: 'bottom-start'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    ok() {
      this.$emit('sendFilter')
      this.visible = false
    },
    reset() {
      this.$emit('reset')
    }
  }
}
</script>
<style lang="less" scoped>
.btnBox {
  margin-top: 24px;
  & div {
    width: 100px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
}
.cancel {
  background: #EEEEEE;
  color: #999999;
  box-sizing: border-box;
  margin-right: 15px;
}

.ok {
  background: #2395F3;
  color: #FFFFFF;
}
.reference {
  width: 84px;
  height: 30px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  cursor: pointer;
  .icon {
    font-size: 20px;
  }
}
.active {
  border-color: #2395F3;
  color: #2395F3;
}
</style>
